import request from "@/utils/request";
//报告数据的报告类型
export function getReportData(data) {
  return request({
    url: "/system/report/getReportList",
    method: "post",
    data
  });
}

//删除报告
export function deleteReport(data) {
  return request({
    url: "/system/report/deleteReportById",
    method: "post",
    data
  });
}

//报告机构表格数据
export function getReportingInfo(data) {
  return request({
    url: "/system/reportInstitution/getReportInstitutionList",
    method: "post",
    data
  });
}

//删除报告机构数据
export function deleteReportInfo(data) {
  return request({
    url: "/system/reportInstitution/deleteReportInstitutionById",
    method: "post",
    data
  });
}

//报告类型 机构类型
export function getPortData(data) {
  return request({
    url: "/system/code/getCodeByStatus",
    method: "get",
    params: data
  });
}

//新增报告数据
export function addNewPortData(data) {
  return request({
    url: "/system/report/insertReport",
    method: "post",
    data
  });
}

//查询单个报告数据
export function getSingleReort(data) {
  return request({
    url: "/system/report/getReportById",
    method: "get",
    params: data
  });
}

//修改报告数据
export function updatePortData(data) {
  return request({
    url: "/system/report/updateReport",
    method: "post",
    data
  });
}

//批量添加pdf
export function uploadReportPdf(data) {
  return request({
    url: "/system/public/uploadAdmin",
    method: "post",
    data
  });
}
//修改报告机构信息
export function saveUpdataInfo(data) {
  return request({
    url: "/system/reportInstitution/updateReportInstitution",
    method: "post",
    data
  });
}

//获取报告数量的数据
export function getReportingData(data) {
  return request({
    url: "/system/report/getByReportPages",
    method: "post",
    data
  });
}

//查看单个接口
export function seeOne(data) {
  return request({
    url: "/system/reportInstitution/getReportInstitutionById",
    method: "get",
    params: data
  });
}

//查看单个接口
export function addUploadFile(data) {
  return request({
    url: "/system/report/save",
    method: "post",
    data
  });
}

//机构智能查询
export function selectLikeInstitutionName(data) {
  return request({
    url: "/system/report/selectLikeInstitutionName",
    method: "get",
    params: data
  });
}

// 报告图表
// 一览
export function getReportImageList(data) {
  return request({
    url: "/system/reportImage/getReportImageList",
    method: "post",
    data
  });
}

// 删除
export function deleteReportImageById(data) {
  return request({
    url: "/system/reportImage/deleteReportImageById",
    method: "post",
    data
  });
}

// 新增
export function insertReportImage(data) {
  return request({
    url: "/system/reportImage/insertReportImage",
    method: "post",
    data
  });
}

// 修改
export function updateReportImage(data) {
  return request({
    url: "/system/reportImage/updateReportImage",
    method: "post",
    data
  });
}

// 智能查询
export function selectLikeByReportName(data) {
  return request({
    url: "/system/reportImage/selectLikeByReportName",
    method: "get",
    params: data
  });
}

// 根据id查询
export function getReportImage(data) {
  return request({
    url: "/system/reportImage/getReportImage",
    method: "get",
    params: data
  });
}

// 新增报告基本信息
export function insertReportInfo(data) {
  return request({
    url: "/system/report/insertReportInfo",
    method: "post",
    data
  });
}

// 报告基本信息详情
export function getReportDataDetails(data) {
  return request({
    url: "/system/report/getReportDataDetails",
    method: "get",
    params: data
  });
}

// 编辑报告基本信息
export function editReportDetaDetails(data) {
  return request({
    url: "/system/report/editReportDetaDetails",
    method: "post",
    data
  });
}

// 新增报告标签信息
export function insertReportKeywordLabel(data) {
  return request({
    url: "/system/report/insertReportKeywordLabel",
    method: "post",
    data
  });
}

// 编辑报告标签信息
export function editReportKeywordLabel(data) {
  return request({
    url: "/system/report/editReportKeywordLabel",
    method: "post",
    data
  });
}

// 查询基本信息详情
export function getReportKeywordLabel(data) {
  return request({
    url: "/system/report/getReportKeywordLabel",
    method: "get",
    params: data
  });
}

// 查询手动标签详情
export function getById(data) {
  return request({
    url: "/system/report/getById",
    method: "get",
    params: data
  });
}

// 编辑/保存手动标签关键字
export function insertHandLabel(data) {
  return request({
    url: "/system/report/insertHandLabel",
    method: "post",
    data
  });
}

//保存已选标签
export function insertMatchReportLabel(data) {
  return request({
    url: '/system/report/label/insertMatchReportLabel',
    method: 'post',
    data
  })
}

// 获取自动匹配历史标签
export function getMatchLabel(data) {
  return request({
    url: '/system/report/label/getMatchLabel',
    method: 'get',
    params: data
  })
}
