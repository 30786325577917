import request from '@/utils/request'

// 查询论文数据(论文数据模块)
export function getInfo(data) {
  return request({
    url: '/system/paperBasic/listPage',
    method: 'post',
    data
  })
}

// 根据id查询论文数据(论文数据模块)
export function getInfoById(data) {
  return request({
    url: '/system/paperBasic/listById',
    method: 'get',
    params: data
  })
}

// 新增论文数据(论文数据模块)
export function addInfo(data) {
  return request({
    url: '/system/paperBasic/save',
    method: 'post',
    data
  })
}

// 编辑论文数据(论文数据模块)
export function updateInfo(data) {
  return request({
    url: '/system/paperBasic/update',
    method: 'post',
    data
  })
}

// 批量删除论文数据(论文数据模块)
export function deleteInfo(data) {
  return request({
    url: '/system/paperBasic/deleteAll',
    method: 'post',
    data
  })
}

// 根据id查询论文作者(论文数据模块)
export function getPaperAuthorById(data) {
  return request({
    url: '/system/paperAuthors/listPage',
    method: 'post',
    data
  })
}

// 新增论文作者(论文数据模块)
export function addPaperAuthor(data) {
  return request({
    url: '/system/paperAuthors/save',
    method: 'post',
    data
  })
}

// 编辑论文作者(论文数据模块)
export function updatePaperAuthor(data) {
  return request({
    url: '/system/paperAuthors/update',
    method: 'post',
    data
  })
}

// 刪除论文作者(论文数据模块)
export function deletePaperAuthor(data) {
  return request({
    url: '/system/paperAuthors/deleteById',
    method: 'post',
    data
  })
}

// 根据id查询论文机构(论文数据模块)
export function getPaperOrganizationById(data) {
  return request({
    url: '/system/paperInstitutions/listAll',
    method: 'post',
    data
  })
}

// 新增论文机构(论文数据模块)
export function addPaperOrganization(data) {
  return request({
    url: '/system/paperInstitutions/save',
    method: 'post',
    data
  })
}

// 编辑论文机构(论文数据模块)
export function updatePaperOrganization(data) {
  return request({
    url: '/system/paperInstitutions/update',
    method: 'post',
    data
  })
}

// 删除论文机构(论文数据模块)
export function deletePaperOrganization(data) {
  return request({
    url: '/system/paperInstitutions/deleteById',
    method: 'post',
    data
  })
}

// 查询会议期刊(会议期刊模块)
export function getPeriodical(data) {
  return request({
    url: '/system/journalMeeting/list',
    method: 'post',
    data
  })
}

// 新增会议期刊(会议期刊模块)
export function addPeriodical(data) {
  return request({
    url: '/system/journalMeeting/save',
    method: 'post',
    data
  })
}

// 修改会议期刊(会议期刊模块)
export function updatePeriodical(data) {
  return request({
    url: '/system/journalMeeting/update',
    method: 'post',
    data
  })
}

// 根据id查询会议期刊(会议期刊模块)
export function getPeriodicalById(data) {
  return request({
    url: '/system/journalMeeting/listById',
    method: 'get',
    params: data
  })
}

// 删除会议期刊(会议期刊模块)
export function deletePeriodical(data) {
  return request({
    url: '/system/journalMeeting/deleteById',
    method: 'delete',
    params: data
  })
}

// 查询会议期刊标签(会议期刊模块)
export function getPeriodicalTag(data) {
  return request({
    url: '/system/journalMeeting/listAll',
    method: 'get',
    params: data
  })
}

// 查询论文作者(论文作者模块)
export function getAuthor(data) {
  return request({
    url: '/system/paperAuthors/list',
    method: 'post',
    data
  })
}

// 查询论文机构(论文机构模块)
export function getOrganization(data) {
  return request({
    url: '/system/paperInstitutions/listPage',
    method: 'post',
    data
  })
}

// 论文数据批量导入
export function uploadInfo(data) {
  return request({
    url: '/system/paperBasic/uploadPdf',
    method: 'post',
    data
  })
}
