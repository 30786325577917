<template>
  <div class="report_data">
    <el-form
      :model="queryInfo"
      class="product_form"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="日期：" class="year_moun">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="报告类别：">
        <dic-port-data
          :code.sync="queryInfo.reportType"
          type-code="009"
          query-name="reportType"
          name="报告类别"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-product-tag
          :code.sync="queryInfo.reportTagsName"
          query-name="reportTagsName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        >
        </dic-product-tag>
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-product-tag
          :code.sync="queryInfo.fieldIdName"
          query-name="fieldIdName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        >
        </dic-product-tag>
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.field)"
        >
          {{ item.name }}：{{
            queryInfo[item.field] ? queryInfo[item.field] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>
    <div class="report_data_table">
      <div class="product_col">
        <div class="product_button">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addReportData"
            class="add"
            >添加</el-button
          >
          <!-- 数据上传 -->
          <el-button
            @click="dialogVisibleTalent = true"
            style="margin-left: 10px"
            >批量上传<i class="el-icon-upload2"></i
          ></el-button>
          <el-button @click="deleteBatch" style="margin-right: 10px"
            >批量删除<i class="jr-iconjr-icon-delect"></i
          ></el-button>
          <!-- 文件导出 -->
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入机构或者报告关键字检索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        style="width: 100%"
        :data="tableData"
        stripe
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{ prop: 'tableData', order: 'descending' }"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        @cell-dblclick="tableDoubleClick"
        class="table ly_table"
        :cell-style="set_cell_style"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column prop="reportId" align="center" label="序号" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                  (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportIntegrity"
          label="数据完整度"
          align="center"
          sortable="custom"
          width="120"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.reportIntegrity"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="产业报告" prop="reportName" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.reportName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportTags"
          label="行业类别"
          align="center"
          width="150"
        >
          <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.reportTags }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.reportTags !== null && scope.row.reportTags !== ''
              "
              class="tableColor"
            >
              {{ scope.row.reportTags.substring(0, 20) }}
              <span v-if="scope.row.reportTags.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="fieldId"
          label="领域类别"
          align="center"
          width="150"
        >
          <!-- <template v-slot="{ row }">
            <span class="tableColor">{{ row.fieldId }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="scope.row.fieldId !== null && scope.row.fieldId !== ''"
              class="tableColor"
            >
              {{ scope.row.fieldId.substring(0, 20) }}
              <span v-if="scope.row.fieldId.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportManager"
          label="发布机构"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <span class="tableColor">{{ row.reportManager }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportType"
          label="报告类别"
          align="center"
          width="80"
        >
          <template v-slot="{ row }">
            <span class="tableColor">{{ row.reportType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="发布日期"
          align="center"
          prop="reportTime"
          sortable="custom"
          width="150"
        >
          <template v-slot="{ row }">
            <span class="tableColor">{{ row.reportTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="页数"
          align="center"
          prop="reportPages"
          sortable="custom"
          width="80"
        />
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template v-slot="{ row }">
            <button-table
              content="下载"
              @buttonClick="downloadClick(row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(row)"
            >
            </button-table>
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              content="删除"
              @buttonClick="deleteProuct(row)"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        :current-page="queryInfo.pageNum"
        :page-sizes="[25, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        :title="dialogType"
        :visible.sync="dialogVisible"
        width="60%"
        @close="handleClose"
        center
      >
        <el-form :model="essentialInfor" label-width="100px">
          <el-form-item
            label="报告类别："
            prop="solutionIndustry"
            v-if="dialogType === '报告类别'"
          >
            <dic-port-reout
              :code.sync="essentialInfor.reportType"
              type-code="009"
            />
          </el-form-item>
          <el-form-item
            label="领域类别："
            prop="domainCategory"
            v-else-if="dialogType === '领域类别'"
          >
            <dic-port-details
              ref="checkboxEnterprise"
              :code.sync="essentialInfor.fieldId"
              tag="领域"
              key="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item
            label="行业类别："
            prop="productType"
            v-else-if="dialogType === '行业类别'"
          >
            <dic-port-details
              ref="checkboxEnterprise"
              :code.sync="essentialInfor.reportTags"
              tag="行业"
              key="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item
            label="发布机构："
            prop="reportManager"
            v-else-if="dialogType === '发布机构'"
            ><el-select
              v-model="essentialInfor.reportManager"
              filterable
              allow-create
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 470px"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.institutionName"
                :value="item.institutionName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发布日期：" prop="reportTime" v-else>
            <el-input v-model="essentialInfor.reportTime" />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="saveItem">保 存</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="预览文件"
        :visible.sync="isViewPdf20"
        :before-close="handleClosePdf"
        :fullscreen="true"
      >
        <iframe
          :src="fileUrl"
          frameborder="0"
          style="width: 98vw; height: 90vh"
        ></iframe>
      </el-dialog>
    </div>
    <!-- 批量上传 -->
    <el-dialog
      title="批量上传"
      :visible.sync="dialogVisibleTalent"
      width="70%"
      @close="handleCloseTalent"
      v-loading="loading"
      center
    >
      <el-row style="margin-bottom: 10px">
        <el-col :span="22">
          <upload-report-pdf
            @filesDo="filesDo"
            ref="uploadTalent"
          ></upload-report-pdf>
        </el-col>
      </el-row>
      <el-table
        :data="dataData17"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center'
        }"
        @selection-change="handleSelectionChange"
        style="overflow: auto"
        height="300"
      >
        <el-table-column
          type="index"
          width="200"
          :label="'序号' + '(总数为' + dataData17.length + ')'"
        >
        </el-table-column>
        <el-table-column prop="name" label="文件名"> </el-table-column>
        <el-table-column prop="size" label="大小">
          <template slot-scope="scope">
            <span>{{ (scope.row.size / 1024 / 1024).toFixed(2) + "MB" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="size" label="大小"></el-table-column> -->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="padding: 0; color: red"
              @click="deleteTalentsData(scope.row, scope.$index)"
            >
              <i class="jr-iconjr-icon-delect" />
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadTalents">确 定</el-button>
        <el-button @click="dialogVisibleTalent = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import { URL } from "../../../../config";
import DataTime from "../../CaseData/dataTime.vue";
import {
  getReportData,
  deleteReport,
  updatePortData,
  addUploadFile,
  selectLikeInstitutionName
} from "../../../api/report";
import { pagination } from "../../../mixins/pagination";
import ButtonTable from "../../../components/ButtonTable.vue";
import DicProductTag from "@/components/DicTagButton.vue";
import DicPortData from "@/components/DicRadioButton.vue";
import DicPortDetails from "../com/dicPortDetails.vue";
import DicPortReout from "../com/dicPortReout.vue";
import Popover from "../../../components/Popover.vue";
import { exportData } from "@/utils/tool";
import UploadReportPdf from "../com/uploadReportPdf.vue";
import { uploadAdminAndPage } from "@/api/common.js";

export default {
  mixins: [pagination],
  name: "ReportData",
  components: {
    DataTime,
    ButtonTable,
    DicProductTag,
    DicPortData,
    DicPortDetails,
    DicPortReout,
    Popover,
    UploadReportPdf
  },
  data() {
    return {
      dataData17: [],
      dialogVisibleTalent: false,
      dialogType: "技术类别", //弹出层title
      dialogVisible: false, //弹出层状态
      multipleTable: [], //表格数据勾选删除的id
      multiple: [], //表格数据勾选完整数据
      queryInfo: {
        columnName: "update_time",
        pageSize: 25, //条数
        pageNum: 1, //页数
        years: "", //日期 年
        month: "", //日期 月
        day: "", //日期 天
        order: 1, //1 倒序   0正序
        queryConditions: "", //搜索字段条件
        reportTags: [], //行业类别
        reportTagsName: "",
        fieldId: [], // 领域类别
        fieldIdName: "",
        reportType: "" //报告类别
      },
      valueI: 0,
      tableData: [],
      tags: [
        {
          name: "报告类别",
          field: "reportType",
          valLength: 0
        },
        {
          name: "行业类别",
          field: "reportTagsName",
          valLength: 0
        },
        {
          name: "领域类别",
          field: "fieldIdName",
          valLength: 0
        }
      ],
      essentialInfor: {
        reportTags: [], //行业类别
        reportTagsChild: [],
        fieldId: [], // 领域类别
        fieldIdChild: "",
        reportType: "", //报告类别
        reportManager: "",
        reportTime: ""
      },
      options: [],
      isViewPdf20: false, //查看报告状态
      fileUrl: "", //报告地址
      loading: false,
      errorMes: null
    };
  },
  created() {
    this.search();
  },
  mounted() {
    this.queryInfo.years = "";
    this.queryInfo.month = "";
    this.queryInfo.day = "";
    this.search();
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  methods: {
    // 机构职能查询
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const companyName = query;
          const res = await selectLikeInstitutionName({
            institutionName: companyName
          });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    //批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { reportIds: [], size: "" });
      } else if (val == 0 && this.multipleTable.length) {
        Object.assign(this.queryInfo, {
          reportIds: this.multipleTable
        });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }
      exportData(
        this.queryInfo, // 后台需要的参数
        "/report/downZip", // 路径
        "application/zip", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "报告数据.zip"
      );
    },
    //选择一级目录
    updateCode(val, tag) {
      this.$nextTick(() => {
        switch (tag) {
          case "领域":
            this.essentialInfor.fieldIdChild = val;
            break;

          case "行业":
            this.essentialInfor.reportTagsChild = val;
            break;

          default:
            break;
        }
      });
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;
      if (this.$refs.checkboxEnterprise) {
        this.$refs.checkboxEnterprise.clear();
      }
    },
    //弹出框关闭
    handleClosePdf(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    //保存弹出层的修改
    async saveItem() {
      //行业
      if (this.essentialInfor.reportTagsChild) {
        this.essentialInfor.reportTags = this.essentialInfor.reportTags.concat(
          this.essentialInfor.reportTagsChild
        );
      }
      //领域
      if (this.essentialInfor.fieldIdChild) {
        this.essentialInfor.fieldId = this.essentialInfor.fieldId.concat(
          this.essentialInfor.fieldIdChild
        );
      }
      this.essentialInfor.reportTags = [
        ...new Set(this.essentialInfor.reportTags)
      ];
      this.essentialInfor.fieldId = [...new Set(this.essentialInfor.fieldId)];
      const res = await updatePortData(this.essentialInfor);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      } else {
        this.$message.error(res.msg);
      }
      this.dialogVisible = false;
      this.search();
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      // 解决筛选条件后点击第二条，换筛选条件后还在第二条问题
      this.queryInfo.pageNum = 1;
      let childrenVal = [];
      if (name === "报告类别") {
        Object.assign(this.queryInfo, { [queryName]: val });
      }
      if (!this.tags.some(item => item.field === queryName)) {
        if (name === "行业类别" || name === "领域类别") {
          this.tags.push({
            name,
            field: queryName,
            valLength: 0
          });
        } else {
          this.tags.push({
            name,
            field: queryName
          });
        }
      }
      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex(el => {
        return el.name === name;
      });
      switch (name) {
        case "行业类别":
          this.queryInfo.reportTags = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.reportTags = this.queryInfo.reportTags.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "领域类别":
          this.queryInfo.fieldId = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.fieldId = this.queryInfo.fieldId.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        default:
          break;
      }
      this.search();
    },
    //查看单个报告
    seeClick(row) {
      if (row.reportKeys) {
        this.fileUrl = row.reportKeys;
        window.open(this.fileUrl);
        // this.isViewPdf20 = true;
      } else {
        this.$alert("该报告暂无附件!");
      }
    },

    //下载单个报告
    downloadClick(row) {
      if (row.reportKey) {
        const url = row.reportKey;
        // let URL = "http://175.24.131.212:9200/system";
        let URL = "https://data.elitego.cn/api/system";
        axios({
          method: "GET",
          url: `${URL}/public/downloadUrlFile`,
          params: { urlStr: url },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization
          }
        })
          .then(res => {
            let blob = new Blob([res.data], {
              type: `application/msword`
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = row.reportKey.substring(
              row.reportKey.lastIndexOf("/") + 1
            ); //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch(err => {
            // this.$message.error("文件下载失败");
          });
      }
    },
    //编辑单个报告
    editItem(row) {
      let text = this.$router.resolve({
        name: "tabIndexReportData",
        query: {
          reportId: row.reportId,
          ReportDataType: 1
        }
      });
      window.open(text.href, "_blank");
    },
    //删除单个报告
    deleteProuct(row) {
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteReport([row.reportId]);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    // 双击单元格
    tableDoubleClick(row, column) {
      if (
        column.label === "报告类别" ||
        column.label === "行业类别" ||
        column.label === "领域类别" ||
        column.label === "发布机构" ||
        column.label === "发布日期"
      ) {
        Object.assign(this.essentialInfor, row);
        //行业字符串转数组
        this.essentialInfor.reportTags = this.formatString(
          this.essentialInfor.reportTags
        );
        //领域字符串转数组
        this.essentialInfor.fieldId = this.formatString(
          this.essentialInfor.fieldId
        );
        if (column.label === "报告类别") {
          this.dialogType = "报告类别";
        } else if (column.label === "行业类别") {
          this.dialogType = "行业类别";
        } else if (column.label === "领域类别") {
          this.dialogType = "领域类别";
        } else if (column.label === "发布机构") {
          this.dialogType = "发布机构";
        } else if (column.label === "发布日期") {
          this.dialogType = "发布日期";
        }
        this.dialogVisible = true;
        if (this.$refs.checkboxEnterprise) {
          this.$refs.checkboxEnterprise.getDicData();
        }
      }
    },
    uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);
      this.queryInfo[name] = "";
      this.search();
    },
    //勾选
    handleSelectionChange(val) {
      this.multiple = val;
      let arr = [];
      val.forEach(item => {
        arr.push(item.reportId);
      });
      this.multipleTable = arr;
    },
    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;
      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }
      this.search();
    },
    // 正序
    caretTop() {
      // this.queryInfo.order = 1;
      // this.search();
    },
    // 倒序
    caretBottom() {
      // this.queryInfo.order = 0;
      // this.search();
    },
    //批量删除
    deleteBatch() {
      if (this.multipleTable.length < 1) {
        this.$message({
          type: "warning",
          message: "请选择需要删除的数据项"
        });
        return;
      }
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteReport(this.multipleTable);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    //添加报告数据
    addReportData() {
      // this.$router.push({
      //   name: "ReportDataDetails",
      //   params: {
      //     reportId: "",
      //     ReportDataType: 0,
      //   },
      // });
      this.$router.push({
        // name: "tabIndexReportData",
        path: "/researchReport/tabIndexReportData",
        params: {
          reportId: "",
          ReportDataType: 0
        }
      });
    },
    //获取表格报告数据
    async search() {
      const res = await getReportData(this.queryInfo);
      if (res.code == 200) {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.tableData.forEach(el => {
          if (el.reportIntegrity) {
            el.reportIntegrity = Number(el.reportIntegrity);
          } else {
            el.reportIntegrity = 0;
          }
        });
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "reportIntegrity") {
        this.queryInfo.columnName = "report_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "reportTime") {
        this.queryInfo.columnName = "report_time";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "reportPages") {
        this.queryInfo.columnName = "report_pages";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    // 父页面添加 日期改变的方法
    dateClick(obj) {
      if (Number(obj.month) < 10 && Number(obj.month) > 0) {
        obj.month = "0" + obj.month;
      }
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (
        column.label === "领域类别" ||
        column.label === "发布机构" ||
        column.label === "行业类别" ||
        column.label === "报告类别" ||
        column.label === "发布日期"
      ) {
        return "cursor:pointer";
      }
    },
    // 批量上传
    filesDo(data) {
      let FileExt = data.map(item => {
        return item.name.replace(/.+\./, "");
      });
      let flag = FileExt.map(item => {
        return ["pdf"].includes(item);
      });
      let errorMe = [];
      flag.forEach((item, index) => {
        if (!item) {
          errorMe.push(index);
        }
      });
      this.dataData17 = data;
      this.errorMes = errorMe.map(item => {
        return (item = item + 1);
      });
    },
    deleteTalentsData(data, index) {
      this.dataData17.splice(index, 1);
      for (let i = 0; i < this.errorMes.length; i++) {
        if (this.errorMes[i] == index + 1) {
          for (let j = 0; j < this.errorMes.length; j++) {
            if (this.errorMes[j] > this.errorMes[i]) {
              this.errorMes[j] = this.errorMes[j] - 1;
            }
          }
          this.errorMes.splice(i, 1);
        }
      }
    },
    async uploadTalents() {
      if (this.errorMes.length > 0) {
        const errorMes1 = this.errorMes.join(",");
        this.$confirm(
          "第" + errorMes1.substring(0, 300) + "条数据格式错误",
          "提示"
        );
      } else {
        if (this.dataData17.length <= 10000) {
          const form = new FormData();
          this.dataData17.forEach((item, index) => {
            form.append("files", this.dataData17[index]);
          });
          this.loading = true;
          const res = await uploadAdminAndPage(form);
          if (res.code == 200) {
            const message1 = res.data;
            const value1 = {
              reportKey: message1.fileAddr,
              reportUrl: message1.fileLook,
              reportPage: message1.pageList,
              md5List: message1.md5List
            };
            const res1 = await addUploadFile(value1);
            if (res1.code == 200) {
              this.search();
              this.dialogVisibleTalent = false;
              // this.$message.success(res1.msg)
              this.$confirm(res1.data.msg, "提示", {
                dangerouslyUseHTMLString: true,
                customClass: "message-logout1"
              }).then(() => {});
            } else if (res1.code == 500) {
              this.$confirm(res1.msg, "提示", {
                dangerouslyUseHTMLString: true,
                customClass: "message-logout1"
              }).then(() => {});
              this.dialogVisibleTalent = false;
            }
            this.loading = false;
          } else {
            this.$confirm(res.msg, "提示", {
              dangerouslyUseHTMLString: true,
              customClass: "message-logout1"
            });
            this.loading = false;
            this.dialogVisibleTalent = false;
          }
        } else {
          this.$message.error("文件个数限制为50");
        }
      }
    },
    handleCloseTalent() {
      this.$refs.uploadTalent.fileArray = [];
      this.dataData17 = [];
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    }
  }
};
</script>

<style lang="less" scoped>
.report_data {
  width: 100%;
  overflow: hidden;

  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .product_form {
    width: 100%;
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    background-color: #fff;
  }

  .report_data_table {
    width: 100%;
    overflow: hidden;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 16px 20px;
    background-color: #fff;

    .product_col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    /deep/ .el-button {
      // margin-right: 10px;
    }
    .product_button {
      display: flex;
      flex-direction: row;
      align-items: center;
      .upload_time {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0 20px;
        .upload_time_icon {
          .isActiveTime {
            color: #1e9fff !important;
          }
        }
        .el-icon-caret-top,
        .el-icon-caret-bottom {
          position: absolute;
          right: -16px;
          color: #c0c4cc;
        }
        .el-icon-caret-top {
          top: 0px;
        }
        .el-icon-caret-bottom {
          top: 7px;
        }
      }
    }
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
.caretTime:hover {
  cursor: pointer;
}

/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>

<style lang="less">
.message-logout1 {
  .el-message-box__message p {
    margin: 0;
    line-height: 24px;
    max-height: 300px !important;
    overflow: auto;
  }
  .el-message-box__container {
    max-height: 300px !important;
    overflow: auto;
  }
}
</style>
